import {config} from "../Constants";
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";

const callApiProfiles = () => {
  const {getAccessToken} = useAuth();
  const navigate = useNavigate();
  const apiUrl = config.url.API_URL;

  const fetchProfile = (personId, handleData) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/profile/"+personId, {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        handleData(data);
      });
  };
  
  const fetchProfileAvailabilityEvents = (personId, handleData) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/profile/"+personId+"/availabilityEvents", {headers,})
      })
      .then(response => {
        if(response.status===200) {
          return response.json();
        }
      })
      .then(data => {
        //console.log(data, '  -> fetchMyAvailabilityEventsApi: data ');
        handleData(data);
      });
  };


  const saveProfileSystemInfo = (personId,formData) => {
    //console.log(formData, ' -- > [callApiMyProfile] saveMyProfile ')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        //console.log(requestOptions,' -> [callApiMyProfile] saveMyProfile requestOptions ')
        return fetch(apiUrl+"/profile/"+personId+"/system-info", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(() => {
        //console.log('successfull save. data: ' + JSON.stringify(data));
        navigate('/profile/'+personId);
      });
  }

  return {
    fetchProfile,
    fetchProfileAvailabilityEvents,
    saveProfileSystemInfo
  };
};

export default callApiProfiles;