import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import ProfileTopSection from './ProfileTopSection';
import {MyProfileContext} from "../../../context/Context";
import callApiProfiles from "../../../api/callApiProfiles";
import {useAuth0} from "@auth0/auth0-react";
import ProfileBottomSection from "./ProfileBottomSection";
import useMyProfile from "../../../hooks/useMyProfile";
import useAuth from "../../../hooks/useAuth";

const Profile = () => {
  const { personId } = useParams();
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  const { isTemp } = useMyProfile();
  const { isCompanyAdmin, isSuperAdmin } = useAuth()
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const [profile, setProfile] = useState({});
  const [profileAvailabilityEvents, setProfileAvailabilityEvents] = useState([]);
  const {fetchProfile,fetchProfileAvailabilityEvents} = callApiProfiles();
  //console.log(profile.registeredAsTemp,'  -> Profile registeredAsTemp');
  const handleLoadProfileData = (data) => {
    setProfile(data);
  }
  const handleLoadProfileAvailabilityEventsData = (data) => {
    setProfileAvailabilityEvents(data);
  }

  const showTitle = () => {
    if(isCompanyAdmin() || isSuperAdmin()) {
      return true
    }
    return !isTemp(myProfilePerson);
  }

  useEffect(() => {
      if(myProfilePerson.id===personId) {
        navigate("/profile");
      } else {
        fetchProfile(personId,handleLoadProfileData);
        fetchProfileAvailabilityEvents(personId,handleLoadProfileAvailabilityEventsData);
      }
  }, [isAuthenticated]);

  return (
    <>
      <ProfileTopSection profileData={profile} showTitle={showTitle()} isMyProfile={false}/>
      <ProfileBottomSection profile={profile} isMyProfile={false} events={profileAvailabilityEvents} />
    </>
  );
};

export default Profile;
