import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import ProfileBanner from "../../profile/ProfileBanner";
import {MyProfileContext} from "../../../../context/Context";
import EditProfileCategoriesSettings from "./EditProfileCategoriesSettings";
import useUrlHelpers from "hooks/useUrlHelpers";
import callApiMyProfile from "../../../../api/callApiMyProfile";
import SubmitProfile from "../editBasicInfo/SubmitProfile";
import EditProfileFiles from "./EditProfileFiles";
import EditProfileCV from "./EditProfileCV";
import TempWizardLink from "./TempWizardLink";

const MyProfileEditTempInfo = () => {
  const {getAvatarUrl} = useUrlHelpers();
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  const {saveMyTempProfile} = callApiMyProfile();

  const getValuesFromMyProfile = (data) => {
    return {
      registeredAsTemp: data.registeredAsTemp===undefined?false:data.registeredAsTemp,
      privateProfile: data.privateProfile===undefined?false:data.privateProfile,
      interestedInRegions: data.interestedInRegions===undefined?[]:data.interestedInRegions,
      interestedInIndustries: data.interestedInIndustries===undefined?[]:data.interestedInIndustries,
      currentStudies: data.currentStudies===undefined?[]:data.currentStudies,
      competences: data.competences===undefined?[]:data.competences,
      yearsSeniority: data.yearsSeniority===undefined?{}:data.yearsSeniority,
      cvFile: data.cvFile===undefined?{}:data.cvFile,
      files: data.files===undefined?[]:data.files
    };
  }
  const defaultValues = getValuesFromMyProfile( myProfilePerson) ;
  const [formData, setFormData] = useState(defaultValues);

  const saveChanges = () => {
    //console.log(formData, '--> onSaveDraft - Submitted data');
    saveMyTempProfile(formData);
  };

  useEffect(() => {
    setFormData(getValuesFromMyProfile( myProfilePerson));
    //console.log(myProfilePerson, ' --> [MyProfileEdit] data reloaded ')
  }, [myProfilePerson])

  let avatarUrl = getAvatarUrl(myProfilePerson.thumbnail?.image, myProfilePerson.firstName,myProfilePerson.lastName)
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={null}
          avatar={avatarUrl}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <TempWizardLink />
          <EditProfileCategoriesSettings
            formData={formData}
            setFormData={setFormData}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar top-navbar-height d-flex flex-column">
            <SubmitProfile
              saveChanges={saveChanges}
            />
            <EditProfileCV
              formData={formData}
              setFormData={setFormData}
            />
            <EditProfileFiles
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MyProfileEditTempInfo;
