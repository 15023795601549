import React, {useContext, useState} from 'react';
import {FeedContext} from "../../../../falcon-react-lib/context/Context";
import FeedCard from "components/app/social/feed/FeedCard";
import classNames from 'classnames';
import DeleteConfirmation from "../../../confirmation/DeleteConfirmation";
import callApiFeed from "../../../../api/callApiFeed";
import CreatePost from "../../social/feed/CreatePost";
import PullToRefresh from 'react-simple-pull-to-refresh'
import Spinner from "react-bootstrap/Spinner";
import useAuth from "../../../../hooks/useAuth";
import CreateTempProfileAsPost from "../../social/feed/CreateTempProfileAsPost";

const FeedContent = () => {
  const {isSuperAdmin,isCompanyAdmin} = useAuth();
  const [feedItemId, setFeedItemId] = useState(null);
  const [feedItemName, setFeedItemName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const {reloadAllDashboardFeeds,deleteFeedItem} = callApiFeed();

  const {feedState: { feedItems,showFeedLoadSpinner }} = useContext(FeedContext);

  // Handle the displaying of the delete modal
  const showDeleteModal = (id, name) => {
    setFeedItemId(id);
    setFeedItemName(name);
    setDisplayConfirmationModal(true);
  };

  // Hide the delete modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteAndHideModal = (feedItemIdToDelete) => {
    deleteFeedItem(feedItemIdToDelete);
    hideConfirmationModal();
  }

  const handleRefresh = async () => {
    console.log(' handleRefresh FeedContent')
    await reloadAllDashboardFeeds();
  }

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh} refreshingContent={<Spinner animation={'border'} role={'status'}></Spinner>}>
        <div  hidden={!showFeedLoadSpinner} id={'spinner-when-loading'} className="text-center">
          <Spinner animation="border" role="status" >
            <span className="visually-hidden">Laster...</span>
          </Spinner>
        </div>
        {(isSuperAdmin() || isCompanyAdmin()) && (
          <CreatePost className="mb-3 border-1 border-primary-subtle" />
        )}
        {(!isSuperAdmin() && !isCompanyAdmin()) && (
          <CreateTempProfileAsPost feedItems={feedItems} className="mb-3 border-1 border-primary-subtle" />
        )}
        {feedItems.map((feed, index) => (
          <FeedCard
            key={feed.id}
            showDeleteModal={showDeleteModal}
            feed={feed}
            className={classNames({
              'mb-3': index + 1 !== feedItems.length,
              'mb-3 mb-lg-0': index + 1 === feedItems.length
            })}
          />
        ))}
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          hideModal={hideConfirmationModal}
          confirmModal={deleteAndHideModal}
          id={feedItemId}
          name={feedItemName}
          message="Er du sikker på at vil slette dette innlegget fra"
        />
      </PullToRefresh>

    </>
  );
};

export default FeedContent;
