import React, {useContext, useEffect} from 'react';
import PropTypes from "prop-types";
import {CategoriesContext} from "../../../context/Context";
import {Alert, Col, Form, Row} from "react-bootstrap";

const RegisterTempProfessionalBackgroundForm = ({ register, getValues, setValue, errors, user }) => {
  const {categoriesState: { competences, currentStudies, yearsSeniorityList }} = useContext(CategoriesContext);

  useEffect(() => {
    const yearsSeniorityValue = getValues('yearsSeniority');
    setValue('yearsSeniority',yearsSeniorityValue)
  }, [user,yearsSeniorityList])

  return (
    <>
      <div className="pb-3">
        <Alert variant="secondary">
            {/*<div className="mb-3 d-md-none fw-bold">*/}
            <div className="mb-3 fw-bold">
              Bakgrunnsinformasjon
            </div>
            <span className="">For at oppdragsgivere skal finne din vikarprofil så må du fylle inn litt opplysninger om din profesjonelle bakgrunn.</span>
        </Alert>
      </div>

      <Form.Group>
        <Form.Label className="fw-bold">Antall års ansenitet *</Form.Label>
        <Form.Select
          id={'yearsSeniority'}
          type="select"
          {...register('yearsSeniority', {
              required: 'Du må velge ansenitet'
            })}
          isInvalid={errors['yearsSeniority']}
          isValid={Object.keys(errors).length > 0 && !errors['yearsSeniority']}
        >
          {yearsSeniorityList.map(option => (
            <option value={option.id} key={option.name}>
              {option.name}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors['yearsSeniority']?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="pt-4">
        <Col lg={6}>
          <Form.Group>
            <Form.Label className="fw-bold">Profesjon/Utdannelse *</Form.Label>
            {competences.map(competence => (
              <Form.Check className="pb-0 pt-0" type="checkbox" id={competence.id + Math.floor(Math.random() * 100)} key={competence.id}>
                <Form.Check.Input
                  type="checkbox"
                  {...register('chosenCompetencesAndCurrentStudies', {
                    required: 'Du må velge minst en av disse'
                  })}
                  value={competence.id}
                  isInvalid={errors['chosenCompetencesAndCurrentStudies']}
                  isValid={Object.keys(errors).length > 0 && !errors['chosenCompetencesAndCurrentStudies']}
                />
                <Form.Check.Label className="ms-2">
                  {competence.description?competence.description:competence.name}
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid" className="mt-0">
                  {errors['chosenCompetencesAndCurrentStudies']?.message}
                </Form.Control.Feedback>
              </Form.Check>
            ))}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label className="fw-bold">Student</Form.Label>
            {currentStudies.map(currentStudy => (
              <Form.Check className="pb-0 pt-0" type="checkbox" id={currentStudy.id + Math.floor(Math.random() * 100)} key={currentStudy.id}>
                <Form.Check.Input
                  type="checkbox"
                  {...register('chosenCompetencesAndCurrentStudies')}
                  value={currentStudy.id}
                  isInvalid={errors["chosenCompetencesAndCurrentStudies"]}
                  isValid={Object.keys(errors).length > 0 && !errors["chosenCompetencesAndCurrentStudies"]}
                />
                <Form.Check.Label className="ms-2">{currentStudy.name}</Form.Check.Label>
                <Form.Control.Feedback type="invalid" className="mt-0">
                  {errors["chosenCompetencesAndCurrentStudies"]?.message}
                </Form.Control.Feedback>
              </Form.Check>
            ))}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

RegisterTempProfessionalBackgroundForm.propTypes = {
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  user: PropTypes.object
};

export default RegisterTempProfessionalBackgroundForm;
