import {useContext} from "react";
import {MyProfileContext} from "../context/Context";
import {isAfter,isBefore, isEqual} from 'date-fns'


const useMyProfile = () => {
  const {myProfileDispatch: myProfileDispatch,} = useContext(MyProfileContext);


  const handleReloadMyProfile = (data) => {
    // console.log(data,' ->[useMyProfile] handleReloadMyProfile')
    myProfileDispatch({
      type: 'RELOAD_MY_PROFILE',
      payload: {
        myProfilePerson: data
      }
    });
  };

  const handleReloadMyProfileEvents = (data) => {
    //console.log(data,' ->[useMyProfile] handleReloadMyProfile')
    myProfileDispatch({
      type: 'RELOAD_MY_AVAILABILITY_EVENTS',
      payload: {
        myAvailabilityEvents: data
      }
    });
  };

  const getTempStatusVisibility = (profile, events) => {
    let tempStatusVisibility = {
      isTemp: false,
      isAvailableAlways: false,
      isAvailableNever: false,
      isAvailableUsingCalendar: false,
      hasAvailabilityEvents: false,
      hasAvailabilityEventsTodayOrInTheFuture: false,
      showTempAvailabilityWarning: false
    }

    if (!profile) {
      return tempStatusVisibility;
    }

    if(profile.registeredAsTemp===true) {
      tempStatusVisibility.isTemp = true;
    }

    if(profile.availabilityStatus==='NONE') {
      tempStatusVisibility.isAvailableNever = true;
    }

    if(profile.availabilityStatus==='ALWAYS') {
      tempStatusVisibility.isAvailableAlways = true;
    }

    if(profile.availabilityStatus==='CALENDAR') {
      tempStatusVisibility.isAvailableUsingCalendar = true;
    }

    if(events?.length>0) {
      tempStatusVisibility.hasAvailabilityEvents = true;
    }

    if(tempStatusVisibility.hasAvailabilityEvents===true) {
      //console.log(events,'  -> events');
      let activeEvents = events.filter(e => {
        const startDate = new Date(e.start);
        const endDate = new Date(e.end);
        let isActive = isAfter(startDate,new Date()); // start date in the future
        if(!isActive) {
          isActive = isEqual(startDate,new Date()); // start date today
        }
        if(!isActive) {
          isActive = isEqual(endDate,new Date()); // end date today
        }
        if(!isActive) {
          isActive = isBefore(startDate,new Date()) && isAfter(endDate,new Date()); // start date in the past and end date in the future
        }
        return isActive;
      });
      if(activeEvents.length>0) {
        tempStatusVisibility.hasAvailabilityEventsTodayOrInTheFuture = true;
      }
    }


    if(tempStatusVisibility.isTemp && !tempStatusVisibility.isAvailableAlways) {
      if(tempStatusVisibility.isAvailableNever) {
        tempStatusVisibility.showTempAvailabilityWarning = true;
      }
      if(tempStatusVisibility.isAvailableUsingCalendar && !tempStatusVisibility.hasAvailabilityEventsTodayOrInTheFuture) {
        tempStatusVisibility.showTempAvailabilityWarning = true;
      }
    }
    return tempStatusVisibility;
  }

  const isTemp = (profile) => {
    if (!profile) {
      return false;
    }
    return profile.registeredAsTemp !== false;
  }

  return {
    handleReloadMyProfile,
    handleReloadMyProfileEvents,
    getTempStatusVisibility,
    isTemp
  };
};

export default useMyProfile;