import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import PropTypes from "prop-types";

const EditSystemInfo = ({ formData, setFormData }) => {

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0 d-inline-block">
            Endre systeminformasjon
          </h5>
        </Card.Header>
        <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="email">
                <Form.Label>Epost</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Epost"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                />
                <p className="text-warning mt-2">
                  OBS: Dette er eposten som brukes for å sende varsler og annen informasjon til brukeren. Dette er IKKE eposten som brukes for innlogging.
                </p>
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="lastName">
                <Form.Label>SsoId (Auth0)<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="SsoId (Auth0)"
                  value={formData.ssoId}
                  name="ssoId"
                  onChange={handleChange}
                />
                <p className="text-warning mt-2">
                  OBS: SsoId knytter brukeren på plattformen til brukeren i Auth0. Endrer du denne endrer du også hvordan denne brukeren logger inn og potensielt får ikke brukeren ligget inn lenger.
                  Bruker du denne funksjonen for å slå sammen to brukere så husk på at rolletilknyttning ligger i Auth0. Så endrer du SsoId må du selv sørge for at eventuelle tilganger blir flyttet over.
                </p>
              </Form.Group>
            </Row>

        </Card.Body>
      </Card>
    </>
  );
};
EditSystemInfo.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};
export default EditSystemInfo;
