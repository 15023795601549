import React, {useContext, useState} from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import Flex from 'falcon-react-lib/components/common/Flex';
import Avatar from 'falcon-react-lib/components/common/Avatar';
import {MyProfileContext} from "../../../../context/Context";
import useUrlHelpers from "hooks/useUrlHelpers";
import callApiFeed from "../../../../api/callApiFeed";
import useMyProfile from "../../../../hooks/useMyProfile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import Confirmation from "../../../confirmation/Confirmation";
import {isToday,parseISO} from "date-fns";
import PropTypes from "prop-types";

const CreateTempProfileAsPost = ({ feedItems, ...rest }) => {
  const {myProfileState: { myProfilePerson,myAvailabilityEvents }} = useContext(MyProfileContext);
  const {getAvatarUrl} = useUrlHelpers();
  const { saveNewFeedItem } = callApiFeed();
  const avatarUrl = getAvatarUrl(myProfilePerson?.thumbnail?.image, myProfilePerson?.firstName,myProfilePerson?.lastName);
  const {getTempStatusVisibility} = useMyProfile();
  const tempStatusVisibility = getTempStatusVisibility(myProfilePerson, myAvailabilityEvents)
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  // Hide the submit form warning modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const confirmInConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    console.log(' --> confirmInConfirmationModal called')
  };

  const afterSuccessfullSaveCallBack = () => {
    console.log('afterSuccessfullSaveCallBack');
  }

  const canSaveNewFeedItem = () => {
    if(feedItems === undefined) {
      return true;
    }
    return !feedItems.some(item =>
      item.user?.id === myProfilePerson.id && isToday(parseISO(item.user?.time))
    );
  }

  const handleShareButtonClick = e => {
    e.preventDefault();
    if(!canSaveNewFeedItem()) {
      setDisplayConfirmationModal(true);
    } else {
      const formData = {
        userId: myProfilePerson.id,
        userType: "PERSON",
        status: null,
        gallery: null,
        url: null,
        tempAvailability: true
      }
      saveNewFeedItem(formData, afterSuccessfullSaveCallBack);
    }
  };

  return (
    <>
      <Card {...rest}>
        <Card.Header className="bg-light">
          <Flex alignItems="center">
            <Avatar size="2xl" src={avatarUrl} />
            <div className="flex-1 ms-2">
              <h5 className="mb-0 fs-0">Nytt innlegg</h5>
            </div>
          </Flex>
        </Card.Header>
        <Card.Body className="p-0">
          <h5 className="m-3">Del vikarprofilen din</h5>
          {tempStatusVisibility?.isTemp === false && (
            <>
              <p className="m-3">
                <FontAwesomeIcon icon={['fas', 'check-circle']} className="me-2 text-warning"/>
                Du er ikke registert som vikar ennå. Når du har laget deg en vikarprofil kan du dele den her på folkin forsiden.
              </p>
              <Flex className="m-3 flex-wrap justify-content-center align-content-center">
                <Button
                  as={Link}
                  variant="link"
                  className="text-warning fs--0  mt-lg-2 ps-0"
                  to="/temp/wizard"
                >
                  Vikarprofilbyggeren
                  <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2"/>
                </Button>
              </Flex>
              <Row className="g-0 mt-3 px-x1 pb-3 pt-3 justify-content-end border border-200">
                <Col xs="auto">
                  <Button size="sm" disabled={true} className="px-4 px-sm-5 float-end" type="button">
                    Del
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {tempStatusVisibility?.isTemp === true && tempStatusVisibility?.showTempAvailabilityWarning === false && (
            <>
              <p className="m-3">
                <FontAwesomeIcon icon={['fas', 'check-circle']} className="me-2 text-success"/>
                Din vikarprofil er søkbar og du er tilgjengelig for oppdrag. Du kan dele med andre her på folkin forsiden
                ved
                å trykke på knappen under.
              </p>
              <Row className="g-0 mt-3 px-x1 pb-3 pt-3 justify-content-end border border-200">
                <Col xs="auto">
                  <Button size="sm" className="px-4 px-sm-5 float-end" type="button" onClick={handleShareButtonClick}>
                    Del
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {tempStatusVisibility?.isTemp === true && tempStatusVisibility?.showTempAvailabilityWarning === true && (
            <>
              <p className="m-3">
                <FontAwesomeIcon icon={['fas', 'check-circle']} className="me-2 text-warning"/>
                Du er registert som vikar, men du er ikke tilgjengelig for oppdrag. Hvis du setter deg tilgjengelig vil du
                bli synlig for oppdragsgivere og du kan dele din vikarprofil her på folkin forsiden.
              </p>
              <Flex className="m-3 flex-wrap justify-content-center align-content-center">
                <Button
                  as={Link}
                  variant="link"
                  className="text-warning fs--0 mt-lg-2 ps-0"
                  to="/profile/accessibility/edit"
                >
                  Sett tilgjengelighet
                  <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2"/>
                </Button>
                <Button
                  as={Link}
                  variant="link"
                  className="text-warning fs--0  mt-lg-2 ps-0"
                  to="/temp/wizard"
                >
                  Vikarprofilbyggeren
                  <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2"/>
                </Button>
              </Flex>
              <Row className="g-0 mt-3 px-x1 pb-3 pt-3 justify-content-end border border-200">
                <Col xs="auto">
                  <Button size="sm" disabled={true} className="px-4 px-sm-5 float-end" type="button">
                    Del
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
      <Confirmation
        showCancelButton={false}
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={confirmInConfirmationModal}
        title={'Nytt innlegg'}
        message={'Du har allerede delt din vikarprofil på folkin forsiden i dag. Du må slette dette tidligere innlegget før du kan dele på nytt.'}
      />
    </>
  );
};

CreateTempProfileAsPost.propTypes = {
  feedItems: PropTypes.array
};

export default CreateTempProfileAsPost;
