import PropTypes from "prop-types";
import {Button, Modal} from 'react-bootstrap';
import React, {useContext} from "react";
import AppContext from "../../falcon-react-lib/context/Context";

const Confirmation = ({ showCancelButton, showModal, hideModal, confirmModal, title, message }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      contentClassName="border"
    >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <span>{message}</span>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          {showCancelButton && (
            <Button
              variant="link"
              onClick={hideModal}
              className="px-4 mx-0"
            >
              Avbryt
            </Button>
          )}
          <Button
            variant="primary"
            type="submit"
            onClick={confirmModal}
            className="px-4 mx-0"
          >
            OK
          </Button>
        </Modal.Footer>
    </Modal>
  )};

Confirmation.propTypes = {
  showCancelButton: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string
};

export default Confirmation;