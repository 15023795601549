import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import EditSystemInfo from './EditSystemInfo';
import ProfileBanner from "../../profile/ProfileBanner";
import useUrlHelpers from "hooks/useUrlHelpers";
import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";
import {useParams} from "react-router-dom";
import callApiProfiles from "../../../../api/callApiProfiles";
import {useAuth0} from "@auth0/auth0-react";
import SubmitProfile from "../../myProfile/editBasicInfo/SubmitProfile";
import Confirmation from "../../../confirmation/Confirmation";

const ProfileEditSystemInfo = () => {
  const { personId } = useParams();
  const { isAuthenticated } = useAuth0();
  const {getAvatarUrl} = useUrlHelpers();
  const {fetchProfile,saveProfileSystemInfo} = callApiProfiles();

  const getValuesFromProfile = (data) => {
    return {
      id: personId,
      email: data.email===undefined?'':data.email,
      ssoId: data.ssoId===undefined?'':data.ssoId
    };
  }
  const defaultValues = getValuesFromProfile({}) ;

  const [formData, setFormData] = useState(defaultValues);
  const [profile, setProfile] = useState();
  const [displaySubmitFormWarningModal, setDisplaySubmitFormWarningModal] = useState(false);
  const [mandatoryMissingFields, setMandatoryMissingFields] = useState([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);


  const handleLoadProfileData = (data) => {
    setFormData(getValuesFromProfile( data));
    setProfile(data);
  }

  // Hide the submit form warning modal
  const hideSubmitFormWarningModal = () => {
    setDisplaySubmitFormWarningModal(false);
  };

  const missingMandatoryFieldsBeforeSave = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];
    //console.log(' missingMandatoryFieldsBeforeSave. formData ' + JSON.stringify(formData));
    if(formData.ssoId === undefined || formData.ssoId === "") {
      mandatoryMissingFieldNames.push("Sso Id");
      missingMandatoryField = true;
    }
    setMandatoryMissingFields(mandatoryMissingFieldNames);
    setDisplaySubmitFormWarningModal(missingMandatoryField);
    return missingMandatoryField;
  }

  // Hide the submit form warning modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const confirmInConfirmationModal = () => {
    //console.log(' --> confirmInConfirmationModal called')
    setDisplayConfirmationModal(false);
    saveProfileSystemInfo(personId,formData);
  };

  const saveChanges = () => {
    console.log(formData, '--> saveChanges - Submitted data');
    if(!missingMandatoryFieldsBeforeSave()) {
      setDisplayConfirmationModal(true);
    }
  };

  useEffect(() => {
    fetchProfile(personId,handleLoadProfileData);
  }, [isAuthenticated]);

  let avatarUrl = getAvatarUrl(profile?.thumbnail?.image, profile?.firstName,profile?.lastName)
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={null}
          avatar={avatarUrl}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <EditSystemInfo
            formData={formData}
            setFormData={setFormData}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar top-navbar-height d-flex flex-column">
            <SubmitProfile
              saveChanges={saveChanges}
            />
          </div>
        </Col>
      </Row>
      <SubmitFormWarning
        showModal={displaySubmitFormWarningModal}
        hideModal={hideSubmitFormWarningModal}
        mandatoryMissingFields={mandatoryMissingFields}
      />
      <Confirmation
        showCancelButton={true}
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={confirmInConfirmationModal}
        title={'Lagre systeminformasjon'}
        message={'Er du HELT sikker på at du vil endre på denne systeminformasjonen. Du kan potensielt gjøre så brukeren ikke får logget inn.'}
      />
    </>
  );
};

export default ProfileEditSystemInfo;
