import TooltipBadge from 'falcon-react-lib/components/common/TooltipBadge';
import React, {useContext} from 'react';
import {Card, Form} from 'react-bootstrap';
import {CategoriesContext} from "../../../../context/Context";
//import callApiMyProfile from "../../../../api/callApiMyProfile";
import PropTypes from "prop-types";
//import EditProfileIntro from "./EditProfileIntro";

const EditProfileCategorySettings = ({ formData, setFormData }) => {
  // console.log(formData,' --> [EditProfileCategorySettings] formData')
  const {categoriesState: { regions, competences, industries, currentStudies, yearsSeniorityList }} = useContext(CategoriesContext);

  const handleChange = e => {
    // console.log('[EditProfileCategorySettings] handleChange e ' + e.target.name);
    if (e.target.type === 'checkbox') {
      setFormData({
        ...formData,
        formHasChanged: true,
        [e.target.name]: e.target.checked
      });
    } else {
      setFormData({
        ...formData,
        formHasChanged: true,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleChangeCompetence = e => {
    let newCompetencesArray = handleChangeCategories(competences,formData.competences,e.target.name,e.target.checked)
    // console.log(newCompetencesArray, ' --> [EditProfileCategorySettings] handleChangeCompetence newCompetencesArray ');
    setFormData({
      ...formData,
      formHasChanged: true,
      competences: newCompetencesArray
    });
  };

  const handleChangeIndustries = e => {
    setFormData({
      ...formData,
      formHasChanged: true,
      interestedInIndustries: handleChangeCategories(industries,formData.interestedInIndustries,e.target.name,e.target.checked)
    });
  };

  const handleChangeRegions = e => {
    setFormData({
      ...formData,
      formHasChanged: true,
      interestedInRegions: handleChangeCategories(regions,formData.interestedInRegions,e.target.name,e.target.checked)
    });
  };

  const handleChangeCurrentStudies = e => {
    setFormData({
      ...formData,
      formHasChanged: true,
      currentStudies: handleChangeCategories(currentStudies,formData.currentStudies,e.target.name,e.target.checked)
    });
  };

  const handleChangeYearsSeniority = e => {
    const selectedYearsSeniority = yearsSeniorityList.find(c => {
      return c.id === e.target.value
    })
    console.log(selectedYearsSeniority,' -> handleChangeYearsSeniority selectedYearsSeniority')
    setFormData({
      ...formData,
      formHasChanged: true,
      yearsSeniority: selectedYearsSeniority
    });
  }

  const handleChangeCategories = (categoriesList,storedCategoriesList,name,checked) => {
    let newList = storedCategoriesList;
    if(checked) {
      let singleElement = categoriesList.find(e => e.id === name);
      newList.push(singleElement);
    } else {
      let singleElement = newList.find(e => e.id === name);
      const index = newList.indexOf(singleElement);
      if (index > -1) {
        newList.splice(index, 1);
      }
    }
    return newList;
  };

  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0 d-inline-block">
          Endre vikarinformasjon
        </h5>
      </Card.Header>
      <Card.Body>
        <div className="ps-2">
          <h6 className="fw-bold">
            Vikarstatus
            <TooltipBadge
              tooltip="Du vil bli søkbar for selskaper som leter etter vikarer"
              icon="question-circle"
            />
          </h6>
          <Form.Check
            type="switch"
            id="registeredAsTemp"
            label="Jeg er vikar"
            className="form-label-nogutter"
            name="registeredAsTemp"
            onChange={handleChange}
            checked={formData.registeredAsTemp}
          />
          <Form.Check
            type="switch"
            id="privateProfile"
            label="Begrenset profil"
            className="form-label-nogutter"
            name="privateProfile"
            onChange={handleChange}
            checked={formData.privateProfile}
          />
        </div>


        <div className="border-dashed border-bottom my-3" />

        <h6 className="fw-bold">
          Ansenitet
          <TooltipBadge
            tooltip="Antall års ansenitet"
            icon="question-circle"
          />
        </h6>

        <div className="ps-2 pb-3">
            <Form.Select
              size="sm"
              value={formData.yearsSeniority.id}
              onChange={handleChangeYearsSeniority}
            >
              {yearsSeniorityList.map(yearsSeniority => (
                <option key={yearsSeniority.id} value={yearsSeniority.id}>{yearsSeniority.name}</option>
              ))}
            </Form.Select>
        </div>

        <h6 className="fw-bold">
          Profesjon/Utdannelse
          <TooltipBadge
            tooltip="Hvilken profesjon har du? Profesjonslisten brukes også for å matche deg mot ledige oppdrag"
            icon="question-circle"
          />
        </h6>

        <div className="ps-2">
          {competences.map(competence => (
            <Form.Check
              type="checkbox"
              key={competence.id}
              id={competence.id}
              label={competence.description?competence.description:competence.name}
              className="form-label-nogutter"
              name={competence.id}
              onChange={handleChangeCompetence}
              checked={formData.competences!==undefined&&formData.competences.map(c => c.id).includes(competence.id)}
            />
          ))}
        </div>


        <h6 className="fw-bold">
          Student
          <TooltipBadge
            tooltip="Er du student Denne listen brukes også for å matche deg mot ledige oppdrag"
            icon="question-circle"
          />
        </h6>

        <div className="ps-2">
          {currentStudies.map(currentStudy => (
            <Form.Check
              type="checkbox"
              key={currentStudy.id}
              id={currentStudy.id}
              label={currentStudy.name}
              className="form-label-nogutter"
              name={currentStudy.id}
              onChange={handleChangeCurrentStudies}
              checked={formData.currentStudies!==undefined&&formData.currentStudies.map(c => c.id).includes(currentStudy.id)}
            />
          ))}
        </div>


        <h6 className="fw-bold pt-2">
          Bransje
          <TooltipBadge
            tooltip="Hvilken bransje ønsker du å jobbe i eller jobber allerede du i? Bransjelisten brukes også for å matche deg mot ledige oppdrag"
            icon="question-circle"
          />
        </h6>

        <div className="ps-2">
          {industries.map(industry => (
            <Form.Check
              type="checkbox"
              key={industry.id}
              id={industry.id}
              label={industry.name}
              className="form-label-nogutter"
              name={industry.id}
              onChange={handleChangeIndustries}
              checked={formData.interestedInIndustries!==undefined&&formData.interestedInIndustries.map(c => c.id).includes(industry.id)}
            />
          ))}
        </div>



        <h6 className="fw-bold pt-2">
          Regioner
          <TooltipBadge
            tooltip="Hvilke geografiske regioner ønsker du å finne oppdrag i? Regionslisten brukes også for å matche deg mot ledige oppdrag"
            icon="question-circle"
          />
        </h6>

        <div className="ps-2">
          {regions.map(region => (
            <Form.Check
              type="checkbox"
              key={region.id}
              id={region.id}
              label={region.name}
              className="form-label-nogutter"
              name={region.id}
              onChange={handleChangeRegions}
              checked={formData.interestedInRegions!==undefined&&formData.interestedInRegions.map(c => c.id).includes(region.id)}
            />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

EditProfileCategorySettings.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};
export default EditProfileCategorySettings;
